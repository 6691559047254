export default [
  // *===============================================---*
  // *--------- CHAT  ---------------------------------------*
  // *===============================================---*
  // {
  //   path: "/apps/chats",
  //   name: "apps-chats",
  //   component: () => import("@/views/apps/chat/Chats.vue"),
  //   title: "چت ها",
  //   meta: {
  //     resource: "Support",
  //     action: "Support",
  //     pageTitle: "چت ها",
  //     // breadcrumb: [
  //     //   {
  //     //     text: 'لیست',
  //     //     active: true
  //     //   },
  //     // ],
  //   },
  // },
  // {
  //   path: "/apps/chats/:id",
  //   name: "apps-chats-id",
  //   resource: "Support",
  //   action: "Support",
  //   component: () => import("@/views/apps/chat/Chat.vue"),
  //   meta: {
  //     // contentRenderer: 'sidebar-left',
  //     contentClass: "chat-application",
  //   },
  // },

  // //  Ticket
  // {
  //   path: "/apps/allTickets",
  //   name: "apps-allTickets",
  //   component: () => import("@/views/apps/Ticket/AllTickets.vue"),
  // },
  // {
  //   path: "/apps/ticketDetail/:id",
  //   name: "apps-ticketDetail-id",
  //   component: () => import("@/views/apps/Ticket/Detail/TicketDetail.vue"),
  // },

  //   Product
  {
    path: "/apps/product/create",
    name: "apps-product-create",
    component: () => import("@/views/apps/product/CreateProduct.vue"),
  },
  {
    path: "/apps/product/list",
    name: "apps-product-list",
    component: () => import("@/views/apps/product/ProductList.vue"),
  },
  {
    path: "/apps/product/edit/:id",
    name: "apps-product-edit-id",
    component: () => import("@/views/apps/product/ProductEdit.vue"),
  },

  //   ProductCategories
  {
    path: "/apps/categories",
    name: "apps-categories",
    component: () => import("@/views/apps/category/ProductCategories.vue"),
  },
  {
    path: "/apps/categories/sub/:id",
    name: "apps-categories-sub-id",
    component: () => import("@/views/apps/category/ProductSubCategories.vue"),
  },
  {
    path: "/apps/categories/CategoryPropertyGroup/:id",
    name: "apps-categories-CategoryPropertyGroup-id",
    component: () => import("@/views/apps/category/CategoryPropertyGroup.vue"),
  },
  {
    path: "/apps/categories/CategoryProperties/:id",
    name: "apps-categories-CategoryProperties-id",
    component: () => import("@/views/apps/category/CategoryProperties.vue"),
  },

  //  User
  {
    path: "/apps/users/list",
    name: "apps-users-list",
    component: () => import("@/views/apps/user/users-list/UsersList.vue"),
  },
  {
    path: "/apps/users/edit/:id",
    name: "apps-users-edit",
    component: () => import("@/views/apps/user/users-edit/UsersEdit.vue"),
  },

  //   Static Contents
  {
    path: "/apps/statics/footer",
    name: "apps-static-footer",
    component: () => import("@/views/apps/static-pages/footer.vue"),
  },
  {
    path: "/apps/statics/aboutUs",
    name: "apps-static-aboutUs",
    component: () => import("@/views/apps/static-pages/AboutUs.vue"),
  },
  {
    path: "/apps/statics/contactUs",
    name: "apps-static-contactUs",
    component: () => import("@/views/apps/static-pages/ContactUs.vue"),
  },

  //   Shipments
  {
    path: "/apps/shipments",
    name: "apps-shipments",
    component: () => import("@/views/apps/shipment/Shipments.vue"),
  },

  // //  Financial
  // {
  //   path: "/apps/financial/payments/list",
  //   name: "apps-financial-payments-list",
  //   component: () => import("@/views/apps/financial/payments/PaymentsList.vue"),
  //   meta: {
  //     resource: "Accountent",
  //     action: "Accountent",
  //   },
  // },
  {
    path: "/apps/orders/list",
    name: "apps-orders-list",
    component: () => import("@/views/apps/orders/OrdersList.vue"),
    meta: {
      resource: "Accountent",
      action: "Accountent",
    },
  },
  {
    path: "/apps/orders/create/:userId",
    name: "apps-orders-create",
    component: () => import("@/views/apps/orders/CreateOrder.vue"),
    meta: {
      resource: "Accountent",
      action: "Accountent",
    },
  },
  {
    path: "/apps/orders/info/:id",
    name: "apps-orders-info",
    component: () => import("@/views/apps/orders/OrderInfo.vue"),
    meta: {
      resource: "Accountent",
      action: "Accountent",
    },
  },
];
